<template>
    <div>
        <!-- <i style="z-index: 1001; position: fixed; top: 25px; left:25px; color:red; font-size:1.5rem;" :class="{connected: broker_connected}" class="fas fa-plug"></i>  -->
    </div>
</template>

<script>

    export default {
        name: 'Mqtt',
        data: function () {
            return {
                userID: null,
                client: null,
                brokerRoot: 'BERadlje',
                broker_connected: false
            }
        },
        methods: {
            onSuccess () {
                this.broker_connected = true

                const topics_for_subscribe = [
                    'data',
                    'diagram',
                    'archive'
                ]

                topics_for_subscribe.forEach((top) => {
                    this.client.subscribe(this.brokerRoot + "/" + top)
                })

            },
            onFailure () {
                this.broker_connected = false
            },
            onConnectionLost () {
                this.broker_connected = false
            },
            onMessageArrived (message) {
                let topic = message.destinationName
                let value = message.payloadString
                topic = topic.replace(this.brokerRoot + '/', '')
                
                /*let t = undefined
                if (topic === "diagram" || topic === "archive") t = 'array'
                if (topic === "data") t = 'json'*/
                
                this.$parent[topic] = eval("(" + value + ")"); 
                //console.log(this.$parent.topics[topic] )
                
            },
            publish (topic, value, retained = false, root_topic = this.brokerRoot, qos = 1) {
                let message = new window.Paho.MQTT.Message(value)
                message.destinationName = root_topic + '/' + topic
                message.qos = qos
                message.retained = retained
                if (this.broker_connected) this.client.send(message)
            },
            convertTopicValue (t, v) {
                if (t == undefined) return undefined
                if (t === 'number') {
                    return Number(v.replace(',', '.'))
                }
                if (t === 'boolean') {
                    return (v !== '0')
                }
                if (t === 'string') {
                    return v
                }
                if (t === 'json') {
                    return JSON.parse(v) || undefined
                }
                if (t === 'array') {
                    return v || undefined
                }
            },
            connect () {
                this.client.connect({ useSSL: true, onSuccess: this.onSuccess, onFailure: this.onFailure, userName: 'radljehd', password: 'radlje2020' }) // connect + callback
            }
        },
        created () {
            this.userID = Math.floor((Math.random() * 1000) + 1)
            this.client = new window.Paho.MQTT.Client('peak-vps.ddns.net', 8084, this.userID.toString()) // wss, secure websocket
            this.client.onConnectionLost = this.onConnectionLost // callback
            this.client.onMessageArrived = this.onMessageArrived // callback
            this.connect()
        }
    }
</script>

<style scoped>
    .connected {
        color: lightgreen !important;
    }
</style>
