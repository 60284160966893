<template>
  <div id="app">
      <div class="navbar">
        <p>
          BE Radlje
        </p>
        <i class="fas fa-reply logoutBtn" @click="this.logOut"></i>
      </div>
      <Mqtt v-if="isUserLogged"></Mqtt>
      <router-view/>
  </div>
</template>

<script>
import Mqtt from './components/mqtt.vue'

export default {
        name: 'app',
        data: function () {
            return {
                  isUserLogged: false,
                  message: '',
                  userName: null,                 //on success login save name here
                  userKey: null,                  //on success login save key here
                  data: undefined,
                  diagram: undefined,
                  archive: undefined
            }
        },
        components: {
            Mqtt
        },
        methods: {
          logOut() {
              this.userName = null;
              this.userKey = null;
              this.isUserLogged = false;
              this.$router.push({ path: '/login' })
          }
        },
        mounted () {
        },
        watch: {
          data: function () {
            if (this.data == undefined) return;
            const c = this.$children.find(_c => typeof _c.loadShema === 'function')
            if (c) c.loadShema()
          },
          diagram: function () {
            if (this.diagram == undefined) return;
            const c = this.$children.find(_c => typeof _c.loadDiagram === 'function')
            if (c) c.loadDiagram()
          },
          archive: function () {
            if (this.archive == undefined) return;
            const c = this.$children.find(_c => typeof _c.loadArchive === 'function')
            if (c) c.loadArchive()
          },
          userName(newUserName) {
            localStorage.userName = newUserName;
          },
          userKey(newUserKey) {
            localStorage.userKey = newUserKey;
          }
        },
        created() {
          if (localStorage.userName) {
                this.userName = localStorage.userName;
            }
            if (localStorage.userKey) {
                this.userKey = localStorage.userKey;
            }
            this.$router.push({ path: '/login' })
        }
    }
</script>


<style>

.logoutBtn {
  font-size: 1.75rem;
  color: #00ffb8;
  position: fixed;
  left: 16px;
  top: 16px;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #262431;
}

#app {
  padding-top: 60px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #262431;
  opacity: 1;
  z-index: 1001;
  border-bottom: solid 1px #00ffb8;
}

.navbar p {
  color: #00ffb8;
  font-size: 1.2rem;
}


</style>
