<template>
    <div class="login">
        <input id="pwd" v-bind:disabled="this.buttonDisable" type="password" placeholder="Vpisite geslo" class="password centered" />
        <button v-bind:disabled="this.buttonDisable" @click="this.loginClick" class="btn centered">PRIJAVA</button>
        <p class="message">{{this.$parent.message}}</p>
    </div>
</template>

<script>

export default {

    name: 'login',
    data() {
        return  {
            buttonDisable: false,        
        }
    },
    methods: {
        loginClick() {
            let key = document.querySelector('.password').value;
            this.$parent.userKey = key;
            if(key) this._checkLogin(); 
            else {
                this.$parent.message = "Vpišite geslo"
            }
            return
        },
        _checkLogin() {
            this.buttonDisable = true;
            this._writeMessage('Prijavljanje.....');
            fetch('https://beradlje.bioenergetika.si/login/?user=' + this.$parent.userKey)
                .then(
                    res => {
                        res.text().then(val => {
                            let r = eval("(" + val + ")");
                            if (r.login === true) {
                                this.$parent.userName = r.userName;
                                this._writeMessage('Uspesna prijava');
                                setTimeout(() => {
                                    this.$router.push('/');
                                    this.buttonDisable = false;
                                    this.$parent.isUserLogged = true;
                                }, 500);
                            } else {
                                this.$parent.userName = this.$parent.userName = null;
                                this._writeMessage('Geslo ni pravilno');
                                this.buttonDisable = false;
                                this.$parent.isUserLogged = false;
                            }
                            return
                        })
                    }
                )
                .catch(err => {
                    this._writeMessage('Napaka v povezavi ' + err);
                    this.buttonDisable = false;
                    this.$parent.isUserLogged = false;
                    return
                });
            
        },
        _writeMessage(t) {
            this.$parent.message = t;
            return
        }
    },
    mounted() {
        this.$parent.message = '';
        if (this.$parent.userName !== null
            && this.$parent.userName !== 'null'
            && this.$parent.userName !== ''
        ) {
            document.getElementById('pwd').value = this.$parent.userKey;
            this._checkLogin();
        }
        return
    },
    created() {
        this.buttonDisable = false;
    }
}
</script>

<style>
.message {
        color: #00ffb8;
        transition: all ease-out .5s;
    }

    .login {
      width: 100%;
      position: relative;
      transform: translateY(100px);
    }
    .password {
        display:block;
        margin-bottom: 15px;
        width: 80%;
        max-width: 300px;
        height: auto;
        background-color: #ffffff00;
        border: none;
        outline: none;
        color: #00ffb8;
        border-bottom: 1px solid #00ffb8;
        margin-bottom: 50px;
        font-size:20px;
    }
    .btn {
        display: block;
        width: 80%;
        max-width: 300px;
        height: 35px;
        border-radius: 75px;
        border: none;
        background-color: #00ffb8;
        outline: none;
    }

    .centered {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .v-centered {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }
</style>