<template>
  <div class="home">

    <div class="shema card" ref='shemaDiv'>
      <p v-if="!isShemaVisible">Nalaganje sheme...</p>
      <object id="svgShema" type="image/svg+xml" :data="mySVG"></object>
    </div>

    
    <div class="card" ref='chartDiv'>
      <p v-if="!isChartVisible">Nalaganje grafa...</p>
      <ejs-chart v-if="isChartVisible" style="width: 100%; height: 100%;" background="#00000000" :title='title' :titleStyle='titleStyle' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :axes='axes' 
      :palettes='palettes' :chartArea='chartArea' :legendSettings='legendSettings' :crosshair='crosshair' :zoomSettings='zoom'>
        <e-series-collection>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='t1' name='T1'> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='t2' name='T2'> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='tp' name='Tp'> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='tg' name='Tg'> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='td' name='Td'> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='crp' name='Crpalka' yAxisName="yAxisRight"> </e-series>
          <e-series :dataSource='diagramData' type='Line' xName='time' yName='vent' name='Ventil' yAxisName="yAxisRight"> </e-series>
        </e-series-collection>
      </ejs-chart>
    </div>
    
    <div class="card" ref='diagramDiv'>
      <p v-if="!isArchiveVisible">Nalaganje proizvodnje...</p>
      <ejs-chart v-if="isArchiveVisible" style="width: 100%; height: 100%;" background="#00000000" :title='title1' :titleStyle='titleStyle' :primaryXAxis='primaryXAxis1' :primaryYAxis='primaryYAxis1'
       :chartArea='chartArea' :legendSettings='legendSettings' :palettes='palettes1' :tooltip='tooltip'>
        <e-series-collection>
          <e-series :dataSource='archiveDataRadlje' type='Column' xName='x' yName='y' name='SPTE Radlje'></e-series>
          <e-series :dataSource='archiveDataTimbles' type='Column' xName='x' yName='y' name='SPTE Timbles'></e-series>
        </e-series-collection>
      </ejs-chart>
    </div>

  </div>

  
</template>

<script>
import Vue from "vue";
import { ChartPlugin, LineSeries, DateTime, Legend, Crosshair, Zoom, Category, ColumnSeries, Tooltip } from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

export default {
  data() {
    return {
      isMounted: false,
      isShemaVisible: false,
      isChartVisible: false,
      isArchiveVisible: false,
      chartYear: 0,
      mySVG: require('../assets/shema.svg'),
      shemeVisible: false,
      diagramData: [],
      archiveData: [],
      archiveDataRadlje: undefined,
      archiveDataTimbles: undefined,

      legendSettings: {
        visible: true,
        position: 'Bottom',
        textStyle: { color: '#00ffb8'}        
      },
      tooltip: {enable: true, format: '<b>${point.x} : ${point.y} MWh</b>'},
      palettes: ["#FF0000", "#001FFF", "#00FF7E", "#FF00E0", "#00E7FF", "#060062", "#FF8854"],
      palettes1: ["#E94649", "#F6B53F"],
      axes: [{
        lineStyle: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        rowIndex: 1, opposedPosition: true,
        crosshairTooltip: { enable: true },
        minimum: 0, maximum: 100,
        name: 'yAxisRight', 
        labelFormat: '{value} %',
        labelStyle: { color: 'rgb(73, 164, 164)' }
      }],
      primaryXAxis: {
        crosshairTooltip: { enable: true },
        valueType: 'DateTime',
        labelFormat: 'HH:mm',
        labelStyle: { color: 'rgb(73, 164, 164)' },
        lineStyle: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 }
      },
      primaryXAxis1: {
        valueType: 'Category',
        labelStyle: { color: 'rgb(73, 164, 164)' },
        lineStyle: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 }
      },
      primaryYAxis: {
        crosshairTooltip: { enable: true },
        title: undefined,
        minimum: 50, maximum: 100,
        labelFormat: '{value} °C',
        labelStyle: { color: 'rgb(73, 164, 164)' },
        lineStyle: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 }
      },
      primaryYAxis1: {
        title: undefined,
        minimum: 0, maximum: 750,
        visible: false,
        lineStyle: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 }
      },
      chartArea: {
        border: { width: 0.2, color: 'rgb(73, 164, 164)' }
      },
      title: 'Parametri zadnjih 6h',
      
      titleStyle:{
        color: "rgb(73, 164, 164)",
        size: '18px'
      },
      crosshair: { enable: true },
      zoom: {
        //enableMouseWheelZooming: true,
        enablePinchZooming: true,
        enableSelectionZooming: true
      },
    }
  },
  provide: {
     chart: [LineSeries, DateTime, Legend, Crosshair, Zoom, Category, ColumnSeries, Tooltip]
  },
  methods: {
    resizeCards() {
      let height = this.$refs.shemaDiv.clientWidth * 0.41;
      if (height != this.$refs.chartDiv.clientHeight) {
        this.$refs.chartDiv.style.height = height + "px";
      }
      if (height != this.$refs.diagramDiv.clientHeight) {
        this.$refs.diagramDiv.style.height = height + "px";
      }
    },
    loadShema() {
       if (this.$parent['data'] == undefined) return; 

       this.isShemaVisible = true;

       let svgShema = undefined;
       let svgShemaContent = undefined;

       svgShema = document.getElementById("svgShema");
       if (svgShema) svgShemaContent = svgShema.contentDocument;
       
       if (svgShema && svgShemaContent) {
          
          svgShemaContent.getElementById("t1").lastChild.innerHTML = this.$parent['data']['InterT1'] + "°C";
          svgShemaContent.getElementById("t2").lastChild.innerHTML = this.$parent['data']['InterT2'] + "°C";
          svgShemaContent.getElementById("tint").lastChild.innerHTML = this.$parent['data']['InterT3'] + "°C";
          svgShemaContent.getElementById("tp").lastChild.innerHTML = this.$parent['data']['InterT4'] + "°C";
          svgShemaContent.getElementById("ventil").lastChild.innerHTML = this.$parent['data']['InterVentil'] + " %";
          svgShemaContent.getElementById("pumpa").lastChild.innerHTML = this.$parent['data']['InterCrpalka'] + " %";
          svgShemaContent.getElementById("tg").lastChild.innerHTML = this.$parent['data']['T2'] + "°C";
          svgShemaContent.getElementById("td").lastChild.innerHTML = this.$parent['data']['T4'] + "°C";

          svgShemaContent.getElementById("timbles_topla").lastChild.innerHTML = Math.round(this.$parent['data']['TimblesHot'] * 10) / 10 + "°C";
          svgShemaContent.getElementById("timbles_hladna").lastChild.innerHTML = Math.round(this.$parent['data']['TimblesCold'] * 10) / 10 + "°C";
          svgShemaContent.getElementById("timbles_kw").lastChild.innerHTML = Math.round(this.$parent['data']['TimblesHeat']) + " kW";

          svgShemaContent.getElementById("radlje_topla").lastChild.innerHTML = Math.round(this.$parent['data']['RadljeHot'] * 10) / 10 + "°C";
          svgShemaContent.getElementById("radlje_hladna").lastChild.innerHTML = Math.round(this.$parent['data']['RadljeCold'] * 10) / 10 + "°C";
          svgShemaContent.getElementById("radlje_kw").lastChild.innerHTML = Math.round(this.$parent['data']['RadljeHeat']) + " kW";

          svgShemaContent.getElementById("date").lastChild.innerHTML = this.$parent['data']['Date'];
          svgShemaContent.getElementById("time").lastChild.innerHTML = this.$parent['data']['Time'];
          
       }
       
    },
    loadDiagram() {
      this.diagramData = [];    //clear array
      if(this.$parent['diagram'] != undefined) {
          this.$parent['diagram'].forEach(el => {
            this.diagramData.push({
              time :  new Date(el['timestamp'] * 1000), 
              t1:     el['InterT1'].replace(',', '.'), 
              t2:     el['InterT2'].replace(',', '.'),
              tp:     el['InterT4'].replace(',', '.'),
              tg:     el['T2'].replace(',', '.'),
              td:     el['T4'].replace(',', '.'),
              crp:    el['Crpalka'].replace(',', '.'),
              vent:   el['Ventil'].replace(',', '.')
            });
          });
      }
      this.isChartVisible = true;
    },
    loadArchive() {
      if(this.$parent['archive'] != undefined) this.archiveData = this.$parent['archive'];
      let year = this.chartYear;
      this.archiveDataRadlje      = this.getDataByMonth('Radlje', year);
      this.archiveDataTimbles     = this.getDataByMonth('Timbles', year);
      this.isArchiveVisible = true;
    },
    getMonthlyData(year, month, key) {
      if(this.archiveData.length === 0) return undefined;

      try {
          let y = parseInt(year);
          let m = parseInt(month);
          let startDate = y + '-' + ('0' + m).slice(-2) + '-01';

          if (m == 12) {
              m = 1;
              y++;
          } else 
              m++;

          let endDate = y + '-' + ('0' + m).slice(-2) + '-01';

          let sD = this.archiveData.find(l => l['Date'] === startDate);
          let eD = this.archiveData.find(l => l['Date'] === endDate);

          if(sD != undefined && eD != undefined) {
              let y1 = parseFloat(sD[key]) || 0;
              let y2 = parseFloat(eD[key]) || 0;
              return (y2 - y1);
          }
      }
      catch(err) {
          console.log('Error: ' + err.message)
      }
      return 0;
    },
    generateMonthObject(yearNr, monthNr, key) {
      let obj = {};
      let monthName;
      switch(monthNr) {
          case 1:
              monthName = 'Jan';
              break;
          case 2:
              monthName = 'Feb';
              break;
          case 3:
              monthName = 'Mar';
              break;
          case 4:
              monthName = 'Apr';
              break;
          case 5:
              monthName = 'Maj';
              break;
          case 6:
              monthName = 'Jun';
              break;
          case 7:
              monthName = 'Jul';
              break;
          case 8:
              monthName = 'Avg';
              break;
          case 9:
              monthName = 'Sep';
              break;
          case 10:
              monthName = 'Okt';
              break;
          case 11:
              monthName = 'Nov';
              break;
          case 12:
              monthName = 'Dec';
              break;
          default:
              monthName = '???';
      }
      obj['x'] = monthName;
      obj['y'] = this.getMonthlyData(yearNr, monthNr, key) || 0;
      obj['u'] = 'MWh';                                           //unit
      return obj;
    },
    getDataByMonth(key, year) {
      let result = [];        //empty array
      for(let i = 1; i <= 12; i++) {
          result.push(this.generateMonthObject(year, i, key));
      }
      return result;
    }
  },
  mounted() {
    this.isMounted = true;
    window.addEventListener("resize", this.resizeCards);
    this.resizeCards();
  },
  created() {
    if (this.$parent.userName != null && this.$parent.userName != 'null') {
      let today = new Date();
      if (today.getMonth() == 0) this.chartYear = today.getFullYear() - 1;
      else this.chartYear = today.getFullYear();
      return;
    }
    this.$router.push('/login');
  },
  computed: {
    title1() {
      return 'Proizvodnja v letu ' + this.chartYear;
    }
  },
  watch: {
    
  }
}

</script>

<style>

.shema object {
  width: 100%;
  height: auto;
}
.home {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
.card {
  align-self: center;
  width: 90%;
  max-width: 1280px;
  background-color: rgba(43,51,88);
  height: auto;
  margin-bottom: 25px;
  border-radius: 15px;

}

.home p {
  color: white;
  font-size: 1.5rem;
}
.card.visible {
  display: block;
}

</style>
